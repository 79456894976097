import "./Base.css";

import { Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { FaGithub, FaLinkedin, FaMoneyBillWave } from "react-icons/fa";

import { Mount } from "./utilities/Transitions";

import {
  Box,
  Flex,
  HStack,
  Button,
  Link,
  Image,
  IconButton,
  Container,
  Text,
  ButtonGroup,
  useDisclosure,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";

const Footer = () => {
  return (
    <Container
      as="footer"
      color="white"
      role="contentinfo"
      py={{ base: "12", md: "16" }}
    >
      <Stack spacing={{ base: "4", md: "5" }}>
        <Stack justify="space-between" direction="row" align="center">
          <ButtonGroup variant="outline">
            <IconButton
              as="a"
              href="https://www.linkedin.com/in/john-seong-9194321a9/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
              icon={<FaLinkedin fontSize="1.25rem" />}
            />
            <IconButton
              as="a"
              href="https://github.com/wonmor"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="GitHub"
              icon={<FaGithub fontSize="1.25rem" />}
            />
          </ButtonGroup>
        </Stack>
        <Text fontSize="sm" color="subtle">
          &copy; {new Date().getFullYear()} <b>John Seong</b>. In Collaboration
          with <b>Opal Camera</b>.
        </Text>
      </Stack>
    </Container>
  );
};

export default function Base(props) {
  return (
    <Box textColor={"white"} backgroundColor="rgb(176, 170, 146)">
      <Box bg={useColorModeValue("gray.900", "gray.100")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <Text className="text-3xl" style={{ fontFamily: "ChicagoFLF" }}>
            Project SpringBoard
          </Text>
        </Flex>
      </Box>

      {/* Main Content goes here... */}
      <Suspense>
        <props.content />
      </Suspense>
      <Footer />
    </Box>
  );
}
